import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RoutePaths } from './_constants';
import { RouteParamKeys } from './_constants/routeKeys';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { ConfirmationEmailComponent } from './confirmation-email';
import { ErrorComponent } from './error';
import { DocumentsComponent } from './feature_components/documents';
import { FundAdminsComponent } from './feature_components/fund-admins';
import { FundHouseAdminsComponent } from './feature_components/fund-house-admins';
import { FundHousesComponent } from './feature_components/fund-houses';
import { FundsComponent } from './feature_components/funds';
import { ImportExportDataComponent } from './feature_components/import-export-data/import-export-data';
import { InvestorDashboardComponent } from './feature_components/investor-dashboard/investor-dashboard';
import { InvestorsComponent } from './feature_components/investors';
import { ProfileComponent } from './feature_components/profile';
import { SchemeInvestorsComponent } from './feature_components/scheme-investors';
import { SchemesComponent } from './feature_components/schemes';
import { LoginComponent } from './login';
import { documentTypesResolver, fundHouseByNameResolver, fundHousesResolver, masterDataResolver } from './resolver';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    {
        path: '',
        resolve:
        {
            documentTypesResponse: documentTypesResolver,
            fundHousesResponse: fundHousesResolver,
            masterDataResponse: masterDataResolver
        },
        canActivate: [AuthGuard],
        children: [
            {
                path: RoutePaths.funds,
                component: FundsComponent,
                canActivate: [AuthGuard],
                data: { roles: [Role.Admin, Role.FundHouseAdmin] },
            },
            {
                path: RoutePaths.fundHouseAdmins,
                component: FundHouseAdminsComponent,
                canActivate: [AuthGuard],
                data: { roles: [Role.Admin] }
            },
            {
                path: RoutePaths.fundHouses,
                component: FundHousesComponent,
                canActivate: [AuthGuard],
                data: { roles: [Role.Admin] }
            },
            {
                path: RoutePaths.investors,
                component: InvestorsComponent,
                canActivate: [AuthGuard],
                data: { roles: [Role.Admin, Role.FundHouseAdmin, Role.FundAdmin] }
            },
            {
                path: RoutePaths.documents,
                component: DocumentsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: RoutePaths.schemes,
                component: SchemesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: RoutePaths.fundAdmins,
                component: FundAdminsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: RoutePaths.schemeInvestors,
                component: SchemeInvestorsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: RoutePaths.profile,
                component: ProfileComponent,
                canActivate: [AuthGuard]
            },
            {
                path: RoutePaths.investorDashboard,
                component: InvestorDashboardComponent,
                canActivate: [AuthGuard],
                data: { roles: [Role.Investor] }
            },
            {
                path: RoutePaths.importExportData,
                component: ImportExportDataComponent,
                canActivate: [AuthGuard],
                data: { roles: [Role.Admin] }
            }
        ]
    },
    {
        path: RoutePaths.confirmEmail,
        component: ConfirmationEmailComponent,
    },
    {
        path: `:${RouteParamKeys.fundHouseName}/${RoutePaths.login}`,
        component: LoginComponent,
        resolve:
        {
            fundHouseByNameResponse: fundHouseByNameResolver
        },
    },
    {
        path: `${RoutePaths.error}/:${RouteParamKeys.errorKey}`,
        component: ErrorComponent
    },
    {
        path: RoutePaths.login,
        component: LoginComponent
    },
    // otherwise redirect to home
    { path: '**', redirectTo: 'login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }


