export namespace LocalStorageKeys {
    export const USER: string = 'user';
    export const FUND_HOUSES: string = 'fundHouses';
    export const DOCUMENT_TYPES: string = 'documentTypes';
    export const MASTER_DATA: string = 'masterData';
    export const TOKEN: string = 'token';
    export const REFRESH_TOKEN: string = 'refreshToken';
}

export namespace SessionStorageKeys {
    export const BUILD_NUMBER: string = 'buildNumber';
}