import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, mergeMap, Observable } from 'rxjs';

import { LocalStorageKeys } from '@app/_constants/storage-keys';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private jwtHelperService: JwtHelperService,
        private localStorageService: LocalStorageService,
        private http: HttpClient
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log(`interceptor called - ${request.url}`);
        // add auth header with jwt if user is logged in and request is to api url
        const token = this.localStorageService.token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        const isRefreshTokenApi = request.url === `${environment.apiUrl}/authentication/refresh-token`;
        if (token && isApiUrl && !isRefreshTokenApi) {

            if (this.jwtHelperService.isTokenExpired(token)) {
                return from(this.authenticationService.refreshToken()).pipe(mergeMap((response) => {
                    if (response.isSuccess) {
                        const newToken = response.data.token;
                        const newRefreshToken = response.data.refreshToken;
                        localStorage.setItem(LocalStorageKeys.TOKEN, JSON.stringify(newToken));
                        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, JSON.stringify(newRefreshToken));

                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${newToken}`
                            }
                        });

                        return next.handle(request);
                    }
                    else {
                        this.authenticationService.logout();
                        return next.handle(request);
                    }
                }
                ));
            }
            else {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });

                return next.handle(request);
            }
        }

        return next.handle(request);
    }

}