import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { RoutePaths } from '@app/_constants';
import { RouteStateKeys } from '@app/_constants/routeKeys';
import { LocalStorageKeys } from '@app/_constants/storage-keys';
import { Idle } from '@ng-idle/core';
import { ApiService } from './api.service';
import { IResponse } from './dto/response.dto';
import { CurrentUserDto, RefreshTokenResponseDto, UserWithTokenDto } from './dto/user.dto';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<CurrentUserDto | null>;
    public user: Observable<CurrentUserDto | null>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private apiService: ApiService,
        private idle: Idle,
        private localStorageService: LocalStorageService
    ) {
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
        this.user = this.userSubject.asObservable();
    }

    public get currentUser() {
        return this.userSubject.value;
    }

    login(username: string, fundHouseId?: number) {
        return this.http.post<IResponse<UserWithTokenDto>>(`${environment.apiUrl}/authentication/login`, { email: username, fundHouseId: fundHouseId }, { observe: 'response', withCredentials: true })
            .pipe(map((httpResponse) => {
                //store user details and jwt token in local storage to keep user logged in between page refreshes
                const response = httpResponse.body;
                if (response.isSuccess && response.data) {
                    //localStorage.setItem('user', JSON.stringify(response.data.user));
                    //localStorage.setItem('token', JSON.stringify(response.data.token));
                    //this.apiService.setToken(response.data.token);
                    //this.userSubject.next(response.data.user);
                }
                return response;
            }));
    }

    login2Factor(code: string) {
        return this.http.post<IResponse<UserWithTokenDto>>(`${environment.apiUrl}/authentication/login-2fa?code=${code}`, {}, { observe: 'response', withCredentials: true })
            .pipe(map(httpResponse => {
                const response = httpResponse.body;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                if (response.isSuccess && response.data) {
                    this.idle.watch();

                    localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(response.data.user));
                    localStorage.setItem(LocalStorageKeys.TOKEN, JSON.stringify(response.data.token));
                    localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, JSON.stringify(response.data.refreshToken));
                    this.apiService.setToken(response.data.token);
                    this.userSubject.next(response.data.user);
                }
                return response;
            }));
    }

    refreshToken(): Promise<IResponse<RefreshTokenResponseDto>> {
        return this.apiService.post<IResponse<RefreshTokenResponseDto>>('/authentication/refresh-token',
            { token: this.localStorageService.token, refreshToken: this.localStorageService.refreshToken });
        // .then(response => {
        //     if (response.isSuccess) {
        //         localStorage.setItem(LocalStorageKeys.TOKEN, JSON.stringify(response.data.token));
        //         localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, JSON.stringify(response.data.refreshToken));
        //         this.apiService.setToken(response.data.token);
        //     }
        //     else {
        //         this.logout(true);
        //     }
        // }
        // );

    }

    logout(isInactivityLogout: boolean = false, returnUrl: string = '') {
        this.idle.stop();

        // remove user from local storage to log user out
        const fundHouseName = this.currentUser?.fundHouse?.shortName;
        let loginUrlPrefix = fundHouseName ? `${fundHouseName}/` : '';
        let loginUrl = `${loginUrlPrefix}${RoutePaths.login}`;

        localStorage.clear();
        this.userSubject.next(null);

        let navigationExtras: NavigationExtras = { state: { [RouteStateKeys.isInactivityLogout]: isInactivityLogout } };
        if (returnUrl) {
            navigationExtras.queryParams = { returnUrl: returnUrl };
        }

        this.router.navigate([loginUrl], navigationExtras);
    }
}