export class RouteParamKeys {
    public static readonly fundHouseName: string = 'fundHouseName';
    public static readonly errorKey: string = 'errorKey';
}

export class RouteStateKeys {
    public static readonly isInactivityLogout = 'il';
}

export class QueryParamKeys {
    public static readonly returnUrl = 'returnUrl';
}