import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

// used to create fake backend

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { NgTiltModule } from '@geometricpanda/angular-tilt';
import { NgIdleModule } from '@ng-idle/core'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MatTableExporterModule } from 'mat-table-exporter';
import { MY_DATE_FORMAT } from './_constants/dateFormat';
import { ErrorInterceptor, JwtInterceptor } from './_helpers';
import { LoadingInterceptor } from './_helpers/loading.interceptor';
import { KpfsDatePipe, KpfsDateTimePipe } from './_pipes/KpfsDateTimePipe';
import { SafeHtmlPipe } from './_pipes/SafeHtmlPipe';
import { AdminComponent } from './admin';
import { AlertComponent } from './alert/alert.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorComponent } from './error';
import { AddUpdateDocumentComponent } from './feature_components/add-update-document';
import { AddUpdateFundComponent } from './feature_components/add-update-fund';
import { AddUpdateFundAdminComponent } from './feature_components/add-update-fund-admin';
import { AddUpdateFundHouseComponent } from './feature_components/add-update-fund-house';
import { AddUpdateFundHouseAdminComponent } from './feature_components/add-update-fund-house-admin';
import { AddUpdateInvestorComponent } from './feature_components/add-update-investor';
import { AddUpdateSchemeComponent } from './feature_components/add-update-scheme';
import { AddUpdateSchemeInvestorComponent } from './feature_components/add-update-scheme-investor';
import { DocumentsComponent } from './feature_components/documents';
import { FundAdminsComponent } from './feature_components/fund-admins';
import { FundHouseAdminsComponent } from './feature_components/fund-house-admins';
import { FundHousesComponent } from './feature_components/fund-houses';
import { FundsComponent } from './feature_components/funds';
import { ImportDataComponent } from './feature_components/import-data';
import { ImportExportDataComponent } from './feature_components/import-export-data/import-export-data';
import { InvestorDashboardComponent } from './feature_components/investor-dashboard/investor-dashboard';
import { InvestorsComponent } from './feature_components/investors';
import { ProfileComponent } from './feature_components/profile';
import { SchemeInvestorDataTableComponent } from './feature_components/scheme-investor-data-table/scheme-investor-data-table';
import { SchemeInvestorsComponent } from './feature_components/scheme-investors';
import { SchemesComponent } from './feature_components/schemes';
import { LoginComponent } from './login';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatTabsModule,
        MatTableModule,
        AppRoutingModule,
        MatSelectModule,
        MatButtonModule,
        MatDialogModule,
        MatPaginatorModule,
        FlexLayoutModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatSortModule,
        MatProgressSpinnerModule,
        NgTiltModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatMomentDateModule,
        MatTableExporterModule,
        MatListModule,
        FormsModule,
        MatTooltipModule,
        MatSnackBarModule,
        NgIdleModule.forRoot()
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        AdminComponent,
        AlertComponent,
        FundHouseAdminsComponent,
        AddUpdateFundHouseAdminComponent,
        AddUpdateFundHouseComponent,
        FundHousesComponent,
        FundsComponent,
        AddUpdateFundComponent,
        InvestorsComponent,
        AddUpdateInvestorComponent,
        DocumentsComponent,
        AddUpdateDocumentComponent,
        ConfirmDialogComponent,
        SchemesComponent,
        AddUpdateSchemeComponent,
        FundAdminsComponent,
        AddUpdateFundAdminComponent,
        AddUpdateSchemeInvestorComponent,
        SchemeInvestorsComponent,
        ImportDataComponent,
        ProfileComponent,
        InvestorDashboardComponent,
        ImportExportDataComponent,
        SchemeInvestorDataTableComponent,
        ErrorComponent,
        SpinnerComponent,
        KpfsDatePipe,
        KpfsDateTimePipe,
        SafeHtmlPipe
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        MatDatepickerModule,
        MatNativeDateModule,
        DatePipe,
        KpfsDatePipe,
        KpfsDateTimePipe

    ],
    bootstrap: [AppComponent]
})

export class AppModule { }