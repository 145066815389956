import { KeyValue } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { InvestorProfileItemModel } from "@app/_models/investor";
import { CommonService } from "@app/_services/common.service";
import { InvestorDashboardDataDto } from "@app/_services/dto/investor-dashboard-data.dto";
import { SchemeInvestorDataOnDateDto } from "@app/_services/dto/scheme-investor-data-on-date.dto";
import { SchemeInvestorDto } from "@app/_services/dto/scheme-investor.dto";
import { InvestorService } from "@app/_services/investor.service";
import { LocalStorageService } from "@app/_services/local-storage.service";
import { SessionStorageService } from "@app/_services/session-storage.service";
import { AppUserBaseComponent } from "@app/app-user-base.component";
import { SchemeInvestorTableDefinitionHelper, SchemeInvestorTableType } from "./table-definition-helper";
@Component({
    selector: 'app-investor-dashboard',
    templateUrl: './investor-dashboard.html',
    styleUrls: ['./investor-dashboard.scss']
})
export class InvestorDashboardComponent extends AppUserBaseComponent implements OnInit {

    investorDashboardData: InvestorDashboardDataDto;
    selectedDatePair: KeyValue<string, Date>;
    selectedSchemeInvestor: SchemeInvestorDto;
    selectedSchemeInvestorDataOnDate: SchemeInvestorDataOnDateDto;
    tableDefinitionHelper: SchemeInvestorTableDefinitionHelper = new SchemeInvestorTableDefinitionHelper(null, null, null);
    schemeInvestorTableType = SchemeInvestorTableType;

    public loading: boolean = false;

    constructor(
        localStorageService: LocalStorageService,
        activatedRoute: ActivatedRoute,
        private investorService: InvestorService,
        commonService: CommonService,
        sessionStorageService: SessionStorageService,
    ) {
        super(activatedRoute, localStorageService, sessionStorageService, commonService);
    }

    investorProfileItems: InvestorProfileItemModel[] = [];

    public async ngOnInit(): Promise<void> {

        await this.investorService.getInvestorDashboardData().then(response => {
            this.investorDashboardData = response.data;
        });
    }

    public async onTabChanged(e) {
        this.setAndloadDataFromIndex(e.index);
    }

    public async onDateSelectionChange(e) {
        await this.loadSchemeInvestorDataDate();
    }

    public canShowDataTable(tableType: SchemeInvestorTableType): boolean {
        switch (tableType) {
            case SchemeInvestorTableType.CommitmentAndContributionSummary:
                {
                    return this.selectedSchemeInvestor.scheme.showCommitmentAndContributionSummary && this.selectedSchemeInvestorDataOnDate.siCommitmentContributions?.length > 0;
                }
            case SchemeInvestorTableType.IncomeAndExpenseSummary:
                {
                    return this.selectedSchemeInvestor.scheme.showIncomeAndExpenseSummary &&
                        (this.selectedSchemeInvestorDataOnDate.siIncomeSummaries?.length > 0 ||
                            this.selectedSchemeInvestorDataOnDate.siExpenseAllocationSummaries?.length > 0 ||
                            this.selectedSchemeInvestorDataOnDate.siSetupAllocationSummaries?.length > 0 ||
                            this.selectedSchemeInvestorDataOnDate.siDistributionSummaries?.length > 0);
                }
            case SchemeInvestorTableType.Performance:
                {
                    return this.selectedSchemeInvestor.scheme.showPerformance && this.selectedSchemeInvestorDataOnDate.siPerformances?.length > 0;
                }
            case SchemeInvestorTableType.TaxationSummary:
                {
                    return this.selectedSchemeInvestor.scheme.showTaxationSummary && this.selectedSchemeInvestorDataOnDate.siTaxationSummaries?.length > 0;
                }
            case SchemeInvestorTableType.CapitalTransactions:
                {
                    return this.selectedSchemeInvestor.scheme.showCapitalTransactions && this.selectedSchemeInvestorDataOnDate.siCapitalTransactions?.length > 0;
                }
            case SchemeInvestorTableType.IncomeDistribution:
                {
                    return this.selectedSchemeInvestor.scheme.showIncomeDistribution && this.selectedSchemeInvestorDataOnDate.siIncomeDistributions?.length > 0;
                }
            case SchemeInvestorTableType.TDSDistribution:
                {
                    return this.selectedSchemeInvestor.scheme.showTDSDistribution && this.selectedSchemeInvestorDataOnDate.sitdsDistributions?.length > 0;
                }
            case SchemeInvestorTableType.OtherTransaction:
                {
                    return this.selectedSchemeInvestor.scheme.showOtherTransactions && this.selectedSchemeInvestorDataOnDate.siOtherTransactions?.length > 0;
                }
            default:
                return false;
        }
    }

    public print() {
        const w = window.open();
        const currentDate = new Date(this.selectedDatePair.value);

        w.document.write('<html><head><title></title>');
        w.document.write('<link rel="stylesheet" href="styles.css"/>');
        w.document.write('</head><body>');
        w.document.write('<div class="printable">');
        w.document.write('<div class="document-header">' + this.selectedSchemeInvestor.schemeFullName + ' - Statement of Account as ' +
            currentDate.toLocaleString('default', { month: 'long' }) + ' ' +
            currentDate.getDate() + ', ' +
            currentDate.getFullYear() +
            '</div>');
        w.document.write(document.getElementById("scheme-investor-data").innerHTML);
        w.document.write('</div>');
        //w.document.write('<script type="text/javascript">addEventListener("load", () => { print(); close(); })</script></body></html>');
        w.document.write('<script type="text/javascript">addEventListener("load", () => { print(); })</script></body></html>');

        w.document.close();
        w.focus();
    }

    private setAndloadDataFromIndex(index: number) {
        this.selectedSchemeInvestor = this.investorDashboardData.schemeInvestors[index];
        this.selectedDatePair = this.selectedSchemeInvestor.dates[0];
        this.loadSchemeInvestorDataDate();
    }

    private async loadSchemeInvestorDataDate() {
        if (this.selectedDatePair) {
            this.loading = true;
            await this.investorService.getSchemeInvestorDataDate(this.selectedSchemeInvestor.id, this.selectedDatePair.value).then(response => {
                if (response.isSuccess) {
                    this.selectedSchemeInvestorDataOnDate = response.data;
                    this.tableDefinitionHelper = new SchemeInvestorTableDefinitionHelper(this.selectedSchemeInvestor, this.selectedSchemeInvestorDataOnDate, this.localStorageService);
                }
                this.loading = false;
            });
        }
    }
}