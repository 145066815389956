import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorPageKeys } from '@app/_constants/errorPageKeys';
import { RouteParamKeys } from '@app/_constants/routeKeys';


@Component({
    selector: 'app-error',
    templateUrl: 'error.component.html',
    styleUrls: ['error.component.scss']
})
export class ErrorComponent {

    error: string = 'Something went wrong!';

    constructor(private route: ActivatedRoute) {
        this.setErrorMessage(Number(route.snapshot.params[RouteParamKeys.errorKey]));

    }

    private setErrorMessage(errorKey: number) {
        switch (errorKey) {
            case ErrorPageKeys.urlFundHouseDoesNotExist:
                {
                    this.error = 'Invalid fund house url! Contact sytem administrator to get the correct url of your fund house.';
                    break;
                }
            default:
                this.error = 'Something went wrong!';
        }
    }
}
